<template>
  <div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 1200px">
        <div class="col-12">
          <div v-html="data.content"></div>
        </div>
        <q-card-actions align="right" class="text-primary">
          <q-btn 
            rounded 
            size="sm" 
            icon="login" 
            style="widh:100%" 
            type="submit"
            label="메뉴이동" 
            @click="select" 
          />
          <q-btn 
            rounded 
            size="sm" 
            icon="cancel" 
            style="widh:100%" 
            label="취소" 
            @click="cancel" 
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-card v-if="!prompt" style="min-width: 1200px">
      <div class="col-12">
        <div v-html="data.content"></div>
      </div>
    </q-card>
    <!-- <q-btn icon="open_in_browser" flat round dense @click="open">
      <q-tooltip class="bg-white text-primary">설명서 열기</q-tooltip>
    </q-btn> -->
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'first-manual',
  data() {
    return {
      editable: true,
      prompt: true,
      data: {
        sysMenuId: '',  // 메뉴 SEQ
        menuNm: '',  // 메뉴 SEQ
        content: '',  // 매뉴얼 내용
        useFlag: 'Y',  // 사용여부
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.$route.query.loginFlag && this.$route.query.loginFlag === 'Y') {
        this.prompt = true
      } else {
        this.prompt = false 
      }
      this.detailUrl = selectConfig.sys.manual.get.url;
      this.getData();
    },
    openManual() {
      window.open('/manual/firstManualDetail'+'','manualpop','width=1280, height=768, menubar=no, status=no, toolbar=no');
    },
    getData() {
      this.$http.url = this.$format(this.detailUrl, 'MENU000303');
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
      },
      () => {
      });
    },
    select() {
      this.prompt = false;
      this.$router.push("/mdm/hrm/userManage");
    },
    cancel() {
      this.prompt = false;
    },
    open() {
      this.prompt = true;
    }
  }
};
</script>
