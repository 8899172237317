var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.prompt,
            callback: function ($$v) {
              _vm.prompt = $$v
            },
            expression: "prompt",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "1200px" } },
            [
              _c("div", { staticClass: "col-12" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.data.content) },
                }),
              ]),
              _c(
                "q-card-actions",
                { staticClass: "text-primary", attrs: { align: "right" } },
                [
                  _c("q-btn", {
                    staticStyle: { widh: "100%" },
                    attrs: {
                      rounded: "",
                      size: "sm",
                      icon: "login",
                      type: "submit",
                      label: "메뉴이동",
                    },
                    on: { click: _vm.select },
                  }),
                  _c("q-btn", {
                    staticStyle: { widh: "100%" },
                    attrs: {
                      rounded: "",
                      size: "sm",
                      icon: "cancel",
                      label: "취소",
                    },
                    on: { click: _vm.cancel },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.prompt
        ? _c("q-card", { staticStyle: { "min-width": "1200px" } }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.data.content) } }),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }